<template>
  <div class="container">
    <!-- 顶部navbar -->
    <div class="navbar" v-if="!isLogin">
      <div class="content">
        <img
          src="../assets/img/logo.png"
          alt=""
          @click="$router.replace('/home')"
          style="cursor: pointer"
        />
        <div class="navbar-right">
          <div class="navbar-item">
            <a href="javascript:;">下载APP</a>
            <img
              src="../assets/img/xiala.png"
              alt=""
              @mouseenter="isShow = true"
              @mouseleave="isShow = false"
            />
            <img
              v-show="isShow"
              class="download-qr"
              :src="net + about_service.app_download"
              alt=""
              style="background: #fff; padding: 2px"
            />
          </div>
          <div class="navbar-item">
            <router-link to="/aboutUs">关于我们</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/login">登录</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/register">注册</router-link>
          </div>
        </div>
      </div>
    </div>
    <navbar v-else></navbar>
    <!-- 轮播图 -->
    <div class="banner">
      <div class="content">
        <el-carousel trigger="click" height="800px">
          <el-carousel-item v-for="(item, index) in banner_imgs" :key="index">
            <img
              v-lazy="net + item.pic"
              alt=""
              @click="bannerTo(item.link_type)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="about-section">
      <div class="content">
        <div class="about-title">
          <h4>{{ notice.title }}</h4>
          <div class="line"></div>
        </div>
        <div class="about-text" v-html="notice.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "../components/Navbar.vue";
import { BASE_URL } from "@constants/constants";
import { getHome, getNoticeDetail } from "@network/home";
export default {
  name: "NoticeDetail",
  data() {
    return {
      net: BASE_URL,
      about_service: {}, //关于和服务
      banner_imgs: [],
      isShow: false,
      notice: {},
      isShow: false,
    };
  },
  created() {
    this.getHome();
    this.getNoticeDetail();
  },

  components: {
    navbar,
  },

  methods: {
    bannerTo(type) {
      if (type == 0) {
        return false;
      } else if (type == 1) {
        this.$router.push("/joinRaise");
      } else if (type == 2) {
        this.$router.push("/customized");
      }
    },
    getNoticeDetail() {
      getNoticeDetail(this.$route.query.id).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({
              message: "请重新登录",
              offset: 300,
            });
            this.$router.replace("/home");
            return false;
          }
          return false;
        }
        if (res.data.content.indexOf("img") !== -1) {
          var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
          var s = res.data.content.match(b); // 取到所有img标签 放到数组 s里面
          for (var i = 0; i < s.length; i++) {
            var srcImg = s[i].replace(b, "$1"); //取src面的内容
            if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
              //若src前4位置或者前5位是http、https则不做任何修改
              //console.log("不做任何修改");
            } else {
              //修改富文本字符串内容 img标签src 相对路径改为绝对路径
              res.data.content = res.data.content.replace(
                new RegExp(srcImg, "g"),
                this.net + srcImg
              );
            }
          }
        }
        this.notice = res.data;
      });
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({ message: "获取失败", offset: 300 });
          return false;
        } else {
          this.about_service = res.data.homePage;
          this.banner_imgs = res.data.carousel;
        }
      });
    },
  },

  computed: {
    isLogin() {
      let token = this.$storage.get("token");
      return token;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../assets/css/AboutUs.less";
</style>